import { computed, observable, makeObservable, action } from 'mobx';

export default class Store {
  brand = null;
  quantity = 1;
  amount = null;
  product = null;
  accountNumber = '';

  constructor(brand, cart, tracker) {
    this.brand = brand;
    this.cart = cart;
    this.tracker = tracker;
    makeObservable(this, {
      brand: observable,
      quantity: observable,
      amount: observable,
      product: observable,
      accountNumber: observable,
      increaseQuantity: action,
      decreaseQuantity: action,
      addToCart: action,
      addToFavorites: action,
      onChangeAccountNumber: action,
      setBrand: action,
      itemsInCart: computed,
      setProduct: action,
      setAmount: action,
      hasValidAccountNumber: computed
    });
  }

  setProduct (product) {
    this.product = product;
  }

  setAmount (amount) {
    this.amount = amount;
  }


  increaseQuantity = () => {
    // this.tracker.trackEvent({
    //   category: 'User',
    //   action: 'Clicked Increase Quantity',
    //   label: `$${this.amount / 100} ${this.cart.cartCurrency} - ${this.brand.name}`,
    //   value: this.quantity
    // });
    this.quantity++;
  }

  decreaseQuantity = () => {
    if (this.quantity > 1) {
      this.quantity--;
    }
    // this.tracker.trackEvent({
    //   category: 'User',
    //   action: 'Clicked Increase Quantity',
    //   label: `$${this.amount / 100} ${this.cart.cartCurrency} - ${this.brand.name}`,
    //   value: this.quantity
    // });
  }

  setBrand = brand => {
    this.brand = brand;
  }

  addToCart = () => {
    this.cart.add(this.quantity, this.amount, this.brand, this.product);
    // this.tracker.trackAddToCart(this.brand, this.amount, this.quantity, this.product);
    this.quantity = 1;
    this.cart.toggleCart();
  }

  addToFavorites = () => {
    this.cart.addToFavorites(this.brand);
  }

  removeFromFavorites = () => {
    this.cart.removeFromFavorites(this.brand);
  }

  purchaseNow = () => {
    return this.cart.checkoutWithSingle(this.brand, this.product, this.amount, this.accountNumber);
  }

  onChangeAccountNumber = (value) => {
    if (typeof value === 'string') {
      this.accountNumber = value.replace(/[^0-9]|\s/, '');
    }
  }

  get itemsInCart () {
    return this.cart.items.length;
  }

  get hasValidAccountNumber () {
    if (!!this.brand.validationRegex) {
      const regex = new RegExp(this.brand.validationRegex + '');
      if (regex.test(this.accountNumber)) {
        return true;
      }
      return false;
    }

    return this.accountNumber.trim().length >= 6
  }
}
